<template>
  <div class="conten" id="pdfcontent" :class="fdf && 'block'">
    <div class="c-title">年度报告 -- {{ reportdetail.add_year }}</div>
    <div class="cont">
      <div class="title">第一步 会员信息</div>
      <div class="setall">
        <div>分会会员总数:</div>
        <div>{{ reportdetail.first_page_member_total }}</div>
      </div>
      <div class="setall">
        <div>货币:</div>
        <div>{{ reportdetail.first_page_currency }}</div>
      </div>
      <div class="setall">
        <div>分会职业会员数:</div>
        <div>{{ reportdetail.first_page_professional_membership_fee }}</div>
      </div>
      <div class="setall">
        <div>分会学生会员费:</div>
        <div>{{ reportdetail.first_page_student_membership_fee }}</div>
      </div>
      <div class="setall">
        <div>IADGE分会干事数:</div>
        <div>{{ reportdetail.first_page_secretary_number }}</div>
      </div>
      <div class="setall">
        <div>下次选举日期:</div>
        <div>
          {{
            reportdetail.first_page_date_next_election &&
            reportdetail.first_page_date_next_election.slice(0, 10)
          }}
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title">第二步 银行信息</div>
      <div class="setall">
        <div>开户人名称:</div>
        <div>{{ reportdetail.second_page_account_holder_name }}</div>
      </div>
      <div class="setall">
        <div>账号:</div>
        <div>{{ reportdetail.second_page_account_number }}</div>
      </div>
      <div class="setall">
        <div>开户行:</div>
        <div>{{ reportdetail.second_page_bank_deposit }}</div>
      </div>
      <div class="setall">
        <div>联系电话:</div>
        <div>{{ reportdetail.second_page_mobile }}</div>
      </div>
      <div class="setall">
        <div>身份证号:</div>
        <div>{{ reportdetail.second_page_idcard }}</div>
      </div>
      <div class="setall">
        <div>单位名称:</div>
        <div>{{ reportdetail.second_page_department }}</div>
      </div>
      <div class="setall">
        <div>SWIFT:</div>
        <div>{{ reportdetail.second_page_swift }}</div>
      </div>
    </div>
    <div class="cont">
      <div class="title">第三步 现金收支</div>
      <div v-if="reportdetail.third_page_currency">
        <div class="setall">
          <div>货币:</div>
          <div>{{ reportdetail.third_page_currency }}</div>
        </div>
        <div class="setall">
          <div>初始余额:</div>
          <div>{{ reportdetail.third_page_initial_balance }}</div>
        </div>
        <div class="setall">
          <div>从IADGE总部及 其他分会收到的款项:</div>
          <div>{{ reportdetail.third_page_money_received }}</div>
        </div>
        <div class="setall">
          <div>联系电话:</div>
          <div>{{ reportdetail.third_page_mobile }}</div>
        </div>
        <div class="setall">
          <div>其他费用:</div>
          <div>{{ reportdetail.third_page_other_expenses }}</div>
        </div>
        <div class="setall">
          <div>应付款:</div>
          <div>{{ reportdetail.third_page_account_payable }}</div>
        </div>
        <div class="setall">
          <div>从发表的论著中 获得的款项等:</div>
          <div>
            {{ reportdetail.third_page_published_works_account_payable }}
          </div>
        </div>
        <div class="setall">
          <div>筹款：</div>
          <div>{{ reportdetail.third_page_raise_money }}</div>
        </div>
        <div class="setall">
          <div>利息收入：</div>
          <div>{{ reportdetail.third_page_Interest_income }}</div>
        </div>
        <div class="setall">
          <div>其他以上 未计入的收入:</div>
          <div>{{ reportdetail.third_page_unaccounted_revenue }}</div>
        </div>
        <div class="setall">
          <div>现金收入总额：</div>
          <div>{{ reportdetail.third_page_total_cash_receipts }}</div>
        </div>
        <div class="setall">
          <div>现金支出总额:</div>
          <div>{{ reportdetail.third_page_total_cash_outlay }}</div>
        </div>
        <div class="setall">
          <div>期末结余：</div>
          <div>{{ reportdetail.third_page_closing_balance }}</div>
        </div>
      </div>

      <div v-else class="setall">本年度没有任何现金收支</div>
    </div>
    <div class="cont">
      <div class="title">第四步 向第三方支付的款项</div>
      <div v-if="reportdetail.fourthly_page_third_party_department">
        <div class="setall">
          <div>第三方单位名称:</div>
          <div>{{ reportdetail.fourthly_page_third_party_department }}</div>
        </div>
        <div class="setall">
          <div>款项金额:</div>
          <div>{{ reportdetail.fourthly_page_amount_money }}</div>
        </div>
        <div class="setall">
          <div>款项缘由:</div>
          <div>{{ reportdetail.fourthly_page_reason_payment }}</div>
        </div>
        <div class="setall">
          <div>支付时间:</div>
          <div>
            {{
              reportdetail.fourthly_page_payment_time &&
              reportdetail.fourthly_page_payment_time.slice(0, 10)
            }}
          </div>
        </div>
        <div class="setall">
          <div>捐款收入:</div>
          <div>{{ reportdetail.fourthly_page_donation_income }}</div>
        </div>
      </div>

      <div v-else class="setall">本年度没有向第三方支付的款项</div>
    </div>
    <div class="cont">
      <div class="title">第五步 向工会捐款</div>
      <div v-if="reportdetail.fifth_page_department">
        <div class="setall">
          <div>单位/个人名称:</div>
          <div>{{ reportdetail.fifth_page_department }}</div>
        </div>
        <div class="setall">
          <div>时间:</div>
          <div>
            {{
              reportdetail.fifth_page_time &&
              reportdetail.fifth_page_time.slice(0, 10)
            }}
          </div>
        </div>
        <div class="setall">
          <div>活动:</div>
          <div>{{ reportdetail.fifth_page_event }}</div>
        </div>
        <div class="setall">
          <div>地点:</div>
          <div>{{ reportdetail.fifth_page_location }}</div>
        </div>
        <div class="setall">
          <div>捐款金额:</div>
          <div>{{ reportdetail.fifth_page_amount_donation }}</div>
        </div>
      </div>

      <div v-else class="setall">没有向工会捐款</div>
    </div>
    <div class="cont">
      <div class="title">第六步 本年度活动</div>
      <div class="setall">
        <div>本年度活动次数</div>
        <div>{{ reportdetail.sixth_activitie_number }}</div>
      </div>
      <div class="setall" style="margin-bottom:28px;">
        <div>活动平均出席人数:</div>
        <div>{{ reportdetail.sixth_average_attendance }}</div>
      </div>
      <div class="cont">
        <div class="title">第七步 即将展开的活动</div>
        <div v-if="reportdetail.seventh_page_activitie_title">
          <div class="setall">
            <div>活动名称</div>
            <div>{{ reportdetail.seventh_page_activitie_title }}</div>
          </div>
          <div class="setall">
            <div>活动描述:</div>
            <div>{{ reportdetail.seventh_page_activitie_describe }}</div>
          </div>
          <div class="setall">
            <div>活动开始时间</div>
            <div>
              {{
                reportdetail.seventh_page_activitie_start_time &&
                reportdetail.seventh_page_activitie_start_time.slice(0, 10)
              }}
            </div>
          </div>
          <div class="setall">
            <div>活动结束时间:</div>
            <div>
              {{
                reportdetail.seventh_page_activitie_end_time &&
                reportdetail.seventh_page_activitie_end_time.slice(0, 10)
              }}
            </div>
          </div>
        </div>
        <div v-else class="setall">没有即将开展的活动</div>
      </div>
    </div>
    <div class="cont">
      <div class="title">第八步 授权与签名</div>
      <div class="setall">
        <div>本年度合作的单位</div>
        <div>{{ reportdetail.eighth_page_cooperating_organization }}</div>
      </div>
      <div class="setall">
        <div>提交人姓名及职位:</div>
        <div>{{ reportdetail.eighth_page_name_position }}</div>
      </div>
    </div>
    <div class="cont">
      <div class="title">第九步 完成报告</div>
    </div>
  </div>
</template>
      
<script>
import ExportSavePdf from "@/utils/html2pdf";
export default {
  data() {
    return {
      fdf: false,
    };
  },
  props: {
    reportdetail: Array,
    default: [],
  },
  mounted() {
    // console.log(this.reportdetail);
  },
  methods: {
    toclick() {
      ExportSavePdf("pdfcontent", "年度报告");
    },
  },
};
</script>
  
      <style scoped lang="scss">
.conten {
  color: #000;
  width: 100%;
  display: none;
  .c-title {
    font-size: 28px;
    margin-bottom: 28px;
    text-align: center;
  }
  .cont {
    margin-bottom: 28px;
    text-align: left;
    .title {
      font-weight: 800;
      font-size: 24px;
    }
  }
  .setall {
    display: flex;
    margin-top: 20px;
    font-size: 18px;
    :nth-child(1) {
      width: 160px;
    }
  }
}
.block {
  display: block !important;
}
</style>
      