<template>
  <div class="main">
    <div class="thetitle">IADGE分会管理</div>
    <annualreport :reportdetail="reportdetail" ref="pdfht"></annualreport>
    <div class="content">
      <div class="title"><div class="infor">分会信息</div></div>
      <div class="introduce">
        <div class="row">
          <div>分会名称：</div>
          <div class="pink">{{ chapter_zone.zone }}</div>
        </div>
        <div class="row">
          <div>分会ID：</div>
          <div>{{ chapter_zone.id }}</div>
        </div>
        <div class="row">
          <div>管理员编号：</div>
          <div>{{ info.client_number }}</div>
        </div>
        <div class="row">
          <div>分会类别：</div>
          <div>{{ chapter_type.type }}</div>
        </div>
        <div class="row">
          <div>分会日期：</div>
          <div>
            {{ info.created_at?.substring(0, 4) }}年{{
              info.created_at?.substring(6, 7)
            }}月{{ info.created_at?.substring(11, 13) }}日
          </div>
        </div>
        <div class="row hand">
          <div>分会规则：</div>
          <div class="pink cot">
            <div class="look hoverbottom" @click="topath('ChapterRules')">
              <div>查看</div>
              <div>
                <img
                  src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>税号：</div>
          <div>{{ info.duty_paragraph || "无" }}</div>
        </div>
        <div class="row">
          <div>税号日期：</div>
          <div>{{ info.duty_paragraph_time || "无" }}</div>
        </div>
        <div class="row">
          <div>上次修改日期：</div>
          <div>{{ info.updated_at?.substring(0, 10) }}</div>
        </div>
        <!-- <div class="row">
          <div>邮件列表：</div>
          <div class="emaillist">
            <div>shfie-hfrie-hdu@sgdhih.org</div>
            <div>shfie-hfrie-hdu@sgdhih.org</div>
            <div>shfie-hfrie@sgdhih.org</div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="functions">
      <div class="thetitle">IADGE分会职能</div>
      <div>请从以下选项中选择要执行的操作</div>
    </div>
    <div class="functional">
      <div class="conent">
        <div class="div1">
          <div class="type">分会简介</div>
          <div class="upda before hoverbottom" @click="topath('Profile')">
            查看/编辑
          </div>
        </div>
        <div class="div1">
          <div class="type">分会干事</div>
          <div class="manager">
            <div
              class="upda before hoverbottom"
              @click="topath('Additionalofficer')"
            >
              添加
            </div>
            <div
              class="upda before hoverbottom"
              @click="topath('Incumbentofficer')"
            >
              查看/编辑
            </div>
          </div>
        </div>
        <div class="div1">
          <div class="type">分会活动表</div>
          <div class="manager">
            <div class="upda before hoverbottom" @click="topath('addactivity')">
              添加
            </div>
            <div class="upda before hoverbottom" @click="topath('hotlist')">
              查看/编辑
            </div>
          </div>
        </div>
      </div>
      <div class="conent2">
        <div class="div1">
          <div class="type">分会会员</div>
          <div>
            <div>
              <div class="upda before hoverbottom" @click="topath('member')">
                查看/编辑
              </div>
            </div>
          </div>
        </div>
        <div class="div1">
          <div class="type">添加分会成员</div>
          <div>
            <div>
              <div class="upda before hoverbottom" @click="topath('addmember')">
                添加
              </div>
            </div>
          </div>
        </div>
        <div class="div1 report">
          <div class="type">年度报告</div>
          <div class="thetitler">
            年度报告只能通过下面的分会管理界面提交。 不接受PDF或邮寄副本。
          </div>
          <div class="date" v-for="(item, index) in reportlist" :key="index">
            <div class="date_3" v-if="item.edit">
              <div>
                {{ item.add_year }}
                <span
                  style="font-size: 14px; font-weight: 400; margin-left: 25px"
                  v-if="item.save_page"
                >
                  — 提交人：{{ item.eighth_page_name_position }}</span
                >
              </div>
              <div class="upda before">
                <div
                  class="hoverbottom"
                  @click="topath('/annualReport', item.add_year)"
                >
                  {{ (item.save_page && "去修改") || "去提交" }}
                </div>
                <div
                  style="margin-top: 1px; margin-left: 25px"
                  class="upda hoverbottom"
                  v-if="item.save_page"
                  @click="pdfht(item.add_year)"
                >
                  去打印
                </div>
              </div>
            </div>
            <div class="date_1" v-if="!item.edit">
              <div>{{ item.add_year }}</div>
              <div>— 提交人：{{ item.eighth_page_name_position }}</div>
            </div>
            <div class="date_2" v-if="!item.edit">
              <div class="completed">已完成</div>
              <div
                class="upda before hoverbottom"
                @click="pdfht(item.add_year)"
              >
                去打印
              </div>
            </div>
          </div>
          <div
            class="all"
            style="
              font-size: 18px;
              color: #000;
              font-weight: 800;
              text-align: center;
              margin-top: 24px;
            "
          >
            年度报告历史
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import annualreport from "../components/annualreport.vue";
import { Session } from "../utils/storage";
import { home, report_list, report_detail } from "../api/twice";
export default {
  data() {
    return {
      info: [],
      chapter_zone: [],
      chapter_type: [],
      reportlist: [],
      reportdetail: [],
    };
  },
  methods: {
    pdfht(year) {
      this.report(year);
      setTimeout(() => {
        this.$refs.pdfht.fdf = true;
      }, 980);
      setTimeout(() => {
        this.$refs.pdfht.toclick();
        this.$refs.pdfht.fdf = false;
      }, 1000);
      // }
    },
    async report(year) {
      const data = await report_detail({
        year: year,
      });
      this.reportdetail = data;
      // return data;
      // console.log(this.reportdetail);
    },
    topath(e, year) {
      this.$router.push({ path: e, query: { year: year } });
    },

    async getreport() {
      const data = await report_list({});
      console.log(data, 12345);
      this.reportlist = data;
    },
    async gethome() {
      const res = await home({});
      this.info = res;
      this.chapter_zone = res.chapter_zone[0];
      this.chapter_type = res.chapter_type[0];
      // console.log(res, 123456);
      this.$store.state.userinfo = res.user;
      this.$store.state.branch = res.chapter_zone[0].zone;
      // console.log(this.$store.state.userinfo, 1234);
    },
  },
  computed: {
    login() {
      if (Session.get("token")) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.gethome();
    this.getreport();
  },
  created() {
    console.log(this.$store.state.login);
    // console.log(this.$store.state.login);
    if (Session.get("token")) {
      console.log(1);
    } else {
      this.$router.push("/");
    }
  },
  components: {
    annualreport,
  },
};
</script>
  
      <style scoped lang="scss">
.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &::after {
    content: url(https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/icon_arrow.svg);
    // position: absolute;
    top: 6px;
    transition: all 1s;
    margin-top: 6px;
    cursor: pointer;
    :hover {
      margin-top: 20px;
    }
  }
}
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .functions {
    margin-top: 80px;
    text-align: center;
    :nth-child(1) {
      margin-bottom: 0px;
    }
    :nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-bottom: 43px;
      margin-top: 4px;
    }
  }
  .functional {
    width: 100%;
    .conent {
      display: flex;
      justify-content: space-between;
      .div1 {
        width: 401px;
        height: 170px;
        border: 4px solid #777777;
        padding: 36px;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .manager {
          display: flex;
          :nth-child(2) {
            margin-left: 28px;
          }
        }
        .before {
          display: flex;
          align-items: center;
          &::after {
            content: "";
            display: inline-block;
            width: 17px; /* 设置图片的宽度 */
            height: 17px; /* 设置图片的高度 */
            background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92.svg"); /* 替换为您的图片 URL */
            background-size: cover; /* 调整图片的显示方式 */
            margin-left: 6px; /* 调整图片与 div 之间的间距 */
          }
        }

        &:hover {
          border: 4px solid #d25144;
        }
      }
    }
    .conent2 {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .div1 {
        width: 401px;
        height: 170px;
        border: 4px solid #777777;
        padding: 36px;
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .thetitler {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-top: 12px;
          margin-bottom: 25px;
        }
        .date {
          .date_1 {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            :nth-child(1) {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              line-height: 21px;
            }
            :nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 16px;
              margin-left: 25px;
            }
          }
          .date_2 {
            display: flex;
            margin-bottom: 25px;
            .completed {
              color: #333333;
              font-size: 18px;
            }
            :nth-child(2) {
              margin-left: 35px;
            }
          }
          .date_3 {
            display: flex;
            flex-direction: column;
            :nth-child(1) {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              line-height: 21px;
            }
            :nth-child(2) {
              font-size: 18px;
              font-weight: 400;
              //   color: #333333;
              line-height: 21px;
              margin-top: 4px;
              cursor: pointer;
            }
          }
        }
        .before {
          display: flex;
          align-items: center;
          &::after {
            content: "";
            display: inline-block;
            width: 17px; /* 设置图片的宽度 */
            height: 17px; /* 设置图片的高度 */
            background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92.svg"); /* 替换为您的图片 URL */
            background-size: cover; /* 调整图片的显示方式 */
            margin-left: 6px; /* 调整图片与 div 之间的间距 */
          }
        }

        &:hover {
          border: 4px solid #d25144;
        }
      }
      .report {
        height: auto;
      }
    }
  }

  .content {
    width: 100%;
    padding: 56px 74px;
    box-sizing: border-box;
    background: #f8e7e5;
    color: #333;
    border-radius: 30px 0 0 0;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
    .introduce {
      .row {
        display: flex;
        margin-bottom: 20px;
        font-size: 18px;
        &:last-child {
          margin-bottom: 0px;
        }
        .emaillist {
          :nth-child(n) {
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            &:last-child {
              margin-bottom: 0px;
            }
            &::before {
              content: "";
              width: 10px;
              display: block;
              margin-right: 8px;
              height: 10px;
              border-radius: 50%;
              border: 2px solid #c81677;
            }
          }
          :nth-child(1) {
            font-weight: 400;
            width: 100%;
            text-align: left;
          }
          :nth-child(2) {
            margin-left: 0px;
          }
        }
        .cot {
          position: relative;

          .look {
            position: absolute;

            display: flex;
            align-items: center;
            position: relative;
            :nth-child(1) {
              font-weight: 400;
              text-align: left;
              color: #c81677;
            }
            img {
              position: absolute;
              top: 1px;
              left: 45px;
              width: 17px;
            }
            // width: auto;
          }
        }

        .history {
          display: flex;
          flex-direction: column;
          // width: 100%;
          :nth-child(n) {
            margin-bottom: 6px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
          :nth-child(1) {
            margin-left: 0px;
            width: 100%;
            text-align: left;
            font-weight: 400;
          }
          :nth-child(2) {
            margin-left: 0px;
          }
        }
        :nth-child(1) {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          width: 126px;
          text-align: right;
        }
        :nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
      